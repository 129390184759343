import React, { useState, useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@headlessui/react'
import { processIRacingTelemetry, validateIRacingData } from '../../utils/iracingUtils'
import { FirebaseContext } from './Firebase'

const IRacingImport = ({ onImport }) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [trackInfo, setTrackInfo] = useState(null)
    const [lapsInfo, setLapsInfo] = useState([])
    const [selectedLap, setSelectedLap] = useState(null)
    const [telemetryData, setTelemetryData] = useState(null)
    const auth = useContext(FirebaseContext)
    const { requireAuth } = auth
    const selectedLapRef = useRef(null)

    useEffect(() => {
        if (selectedLap && selectedLapRef.current) {
            selectedLapRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        }
    }, [selectedLap, isOpen])

    const processIRacingFile = async (file) => {
        setIsProcessing(true)
        setTrackInfo(null)
        setLapsInfo([])
        setSelectedLap(null)
        setTelemetryData(null)
        
        try {
            const buffer = await file.arrayBuffer()
            const result = await processIRacingTelemetry(buffer)
            
            if (result.trackInfo) {
                setTrackInfo(result.trackInfo)
            }
            if (Array.isArray(result.allLaps)) {
                setLapsInfo(result.allLaps)
                setSelectedLap(result.lap)
            }
            
            setTelemetryData(result)
            setIsOpen(true)
        } catch (error) {
            console.error(`Error processing iRacing file: ${error}`)
            onImport(null, error)
        } finally {
            setIsProcessing(false)
        }
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0]
        if (file && file.name.endsWith('.ibt')) {
            requireAuth(() => processIRacingFile(file))
        }
    }

    const handleLapSelect = (lapNumber) => {
        setSelectedLap(lapNumber)
    }

    const handleImport = () => {
        if (!telemetryData || !selectedLap) return

        try {
            // Find the selected lap's data
            const selectedLapData = telemetryData.allLaps.find(lap => lap.lapNumber === selectedLap)
            if (!selectedLapData || !selectedLapData.points) {
                throw new Error('Selected lap data not found')
            }

            validateIRacingData(selectedLapData.points)
            
            // Create points array with lap time
            const pointsWithLapTime = selectedLapData.points.map(point => ({
                ...point,
                lapTime: selectedLapData.formattedTime
            }))

            const importData = {
                carType: trackInfo.carName,
                vehicleId: trackInfo.carId,
            }
            
            onImport(pointsWithLapTime, importData, telemetryData.id)
            setIsOpen(false)
        } catch (error) {
            console.error('Error importing lap:', error)
            onImport(null, null, null, error)
        }
    }

    return (
        <>
            <input
                type="file"
                accept=".ibt"
                onChange={handleFileSelect}
                disabled={isProcessing}
                style={{ display: 'none' }}
                id="iracing-file-input"
            />
            <label 
                htmlFor="iracing-file-input" 
                className="flex items-center space-x-3 px-8 py-3 bg-white border-2 border-gray-200 text-gray-700 rounded-xl hover:bg-gray-50 transition-colors cursor-pointer"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="opacity-80">
                    <path d="M12 3L20 7.5V16.5L12 21L4 16.5V7.5L12 3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 12L20 7.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 12V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 12L4 7.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className="text-lg font-medium whitespace-nowrap">
                    {isProcessing ? 'Processing...' : 'IMPORT IRACING LAP'}
                </span>
            </label>

            <Dialog 
                open={isOpen} 
                onClose={() => setIsOpen(false)}
                className="fixed inset-0 z-50 overflow-y-auto"
            >
                <div className="flex min-h-screen items-center justify-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black/30" />

                    <div className="relative mx-auto max-w-2xl w-full bg-white rounded-2xl shadow-lg p-6 space-y-6">
                        <Dialog.Title className="text-2xl font-bold text-gray-900">
                            Import iRacing Lap
                        </Dialog.Title>

                        {trackInfo && (
                            <div className="space-y-2">
                                <h3 className="text-lg font-semibold text-gray-900">Track Information</h3>
                                <div className="bg-gray-50 p-4 rounded-xl space-y-2">
                                    <p className="text-gray-700">Name: {trackInfo.name}</p>
                                    {trackInfo.configuration && 
                                        <p className="text-gray-700">Configuration: {trackInfo.configuration}</p>
                                    }
                                    <p className="text-gray-700">
                                        Length: {(trackInfo.length * 0.000621371).toFixed(3)} miles
                                    </p>
                                    <p className="text-gray-700">
                                        Car: {trackInfo.carName}
                                        <span className="text-gray-400 text-sm ml-2">
                                            ({trackInfo.carId})
                                        </span>
                                    </p>
                                </div>
                            </div>
                        )}

                        {lapsInfo.length > 0 && (
                            <div className="space-y-4">
                                <h3 className="text-lg font-semibold text-gray-900">Select a Lap</h3>
                                <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
                                    {lapsInfo.map((lap) => (
                                        <div 
                                            key={lap.lapNumber}
                                            ref={selectedLap === lap.lapNumber ? selectedLapRef : null}
                                            onClick={() => handleLapSelect(lap.lapNumber)}
                                            className={`p-4 rounded-xl cursor-pointer transition-all
                                                ${selectedLap === lap.lapNumber 
                                                    ? 'bg-indigo-50 border-2 border-indigo-300 shadow-sm' 
                                                    : 'bg-gray-50 hover:bg-gray-100'}`}
                                        >
                                            <div className="flex justify-between items-center">
                                                <span className="font-medium text-gray-900">
                                                    Lap {lap.lapNumber}
                                                </span>
                                                <span className="font-mono text-gray-700">
                                                    {lap.formattedTime}
                                                </span>
                                                <span className={`font-medium ${lap.isClean 
                                                    ? 'text-emerald-600' 
                                                    : 'text-amber-600'}`}
                                                >
                                                    {lap.isClean ? '✓ Clean' : '⚠ Off Track'}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="flex justify-end space-x-4 pt-4 border-t">
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        className="px-4 py-2 rounded-lg font-medium text-gray-700 hover:bg-gray-100"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleImport}
                                        disabled={!selectedLap}
                                        className={`px-6 py-2 rounded-lg font-medium transition-colors
                                            ${!selectedLap 
                                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                                                : 'bg-indigo-600 text-white hover:bg-indigo-700'}`}
                                    >
                                        Import Selected Lap
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    )
}

IRacingImport.propTypes = {
    onImport: PropTypes.func.isRequired
}

export default IRacingImport 